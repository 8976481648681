<template>
  <div class="Workspace">
    <header class="Workspace__header">
      <div
          class="Workspace__header-container d-flex justify-space-between align-center"
      >
        <div class="d-flex align-center">
          <p class="Workspace__header-title">
            <router-link
                to="/workspaces"
                tag="a"
                class="Workspace__header-title-link"
            >
              Workspaces
            </router-link>
            <v-icon small class="Workspace__header-title-icon">
              mdi-chevron-right
            </v-icon>
            <span
                class="Workspace__header-title-name"
            >
              {{ workspace.name }}
            </span>
          </p>
        </div>
        <v-btn
            v-if="canEdit()"
            @click.stop="updateWorkspaceInfo"
            :loading="updateWorkspaceLoading"
            class="item"
            outlined
            dense
            name="Save"
            label="Save" >
          Save
        </v-btn>
      </div>
    </header>
    <v-col v-if="!workspaceLoading">
      <div style="display: flex; flex-direction: row; justify-content: space-around; padding: 25px">
        <div style="flex-grow: 0; min-width: 50%">
          <span style="font-size: 14pt">
            {{ workspace.description }}
          </span>
          <v-row class="Workspace__messageInput">
            <v-col style="max-width: 90%;">
              <v-textarea
                  class="TextArea"
                  outlined
                  dense
                  :placeholder="'Message'"
                  hide-details="auto"
                  novalidate
                  :rows="5"
                  v-model="message"
                  id="message_input2"
                  @keydown.enter.prevent=""
                  :loading="sendMessageLoading"
              >
              </v-textarea>
              <v-row class="Workspace__files" v-if="filesAccumulated.length !== 0">
                <div class="Workspace__filesContainer">
                  <div v-for="(file, index) in filesAccumulated" :key="index" class="fileItem">
                    <div style="position:relative; display: flex; justify-content: start">
                      <button @click.stop="onFileDelete(index)" class="closeButton">
                        <span>&times;</span>
                      </button>
                      <img v-if="file.type.includes('image')" :src="createObjectURL(file)" class="image"/>
                      <div style="height: 80px">
                        <img v-if="!file.type.includes('image')" class="document" :src="`${publicPath}/icons/file-icon.svg`"/>
                      </div>
                      <span style="top: 0px">
                   {{ file.name }}
                 </span>
                    </div>
                  </div>
                </div>
              </v-row>
              <v-row>
                <v-btn
                    v-if="(message?.trim()?.replaceAll('\n','') || '') !== ''"
                    @click.stop="saveWorkspacePrompt"
                    :loading="workspaceActionLoading"
                    style="width: 100px; font-size: 8pt; background: #ddf0ff; margin-top: 20px; margin-left: 15px; border: 1px #43c1f8 solid;"
                    outlined
                    dense
                    name="Save Prompt"
                    label="Save Prompt">
                  Save Prompt
                </v-btn>
              </v-row>
              <v-row>
                <workspace-prompt @onPromptSelect="(data) => { this.message = data.prompt }"/>
              </v-row>
            </v-col>
            <v-col style="max-width: 3%">
              <v-row>
                <v-btn icon @click.stop="send()" class="Chat__send_button">
                  <v-icon large> mdi-chevron-right </v-icon>
                </v-btn>
              </v-row>
              <v-row style="margin-top: 12px">
                <v-file-input
                    label="File input"
                    hide-input
                    v-model="files"
                    class="Workspace__attach_button"
                    multiple
                    accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
                    @change="onFileChange"
                ></v-file-input>
              </v-row>
              <v-row>
                <v-btn icon @click.stop="isWorkspaceKnowledgeOpen = true">
                  <v-icon> mdi-content-save </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <div style="flex-grow: 0">
          <div class="Workspace__settings">
            <div class="section">
              <div class="title">
                Chat Model
              </div>
              <div class="content">
                <v-autocomplete
                    :disabled="!canEdit()"
                    class="mb-3"
                    style="width: 250px"
                    outlined
                    dense
                    label="AI Model"
                    :items="models"
                    v-model="selectedModel"
                    item-text="label"
                    item-value="value"
                    @change="(value) => {}"
                ></v-autocomplete>
              </div>
            </div>
            <div class="section">
              <div class="title">
                Tools
                <v-btn style="" v-if="canEdit()" icon @click.stop="isToolSelectDialog = true">
                  <v-icon small> mdi-pencil-outline </v-icon>
                </v-btn>
              </div>
              <div class="content">
                <div class="selectedToolsList">
                  <div class="selectedToolItem" v-for="tool in workspace.tools" :key="tool.id">
                    {{ tool.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="section">
              <div class="title">
                System Prompt
              </div>
              <div class="content">
                <v-row>
                  <v-col>
                    <v-textarea
                        :disabled="!canEdit()"
                        class="TextAreaSystemPrompt"
                        outlined
                        dense
                        :placeholder="'System Prompt'"
                        hide-details="auto"
                        novalidate
                        :rows="5"
                        v-model="systemPromptText"
                    >
                    </v-textarea>
                  </v-col>
                  <v-col>
                    <v-row>
                      <v-autocomplete
                          :disabled="!canEdit()"
                          class="mb-3 mt-3"
                          outlined
                          dense
                          clearable
                          label="System Prompts"
                          :items="systemPrompts"
                          v-model="selectedSystemPrompt"
                          item-text="name"
                          item-value="id"
                          @change="(value) => updateSelectedSystemPrompt(value)"
                          :append-outer-icon="systemPromptLoading ? 'mdi-loading mdi-spin' : 'mdi-reload'"
                          @click:append-outer="this.getSystemPrompts"
                      ></v-autocomplete>
                    </v-row>
                    <v-row>
                      <v-btn v-if="canEdit()" @click.stop="saveSystemPrompt()" class="CreateWorkspace__sendButton" :loading="updateSystemPromptLoading">
                        Save
                      </v-btn>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
            </div>
            <div class="section">
              <div class="title">
                Max Tokens
              </div>
              <div class="content">
                <div class="maxTokens">
                  <v-slider
                      :disabled="!canEdit()"
                      v-model="workspace.maxTokens"
                      :min="1000"
                      :max="15000"
                  ></v-slider>
                  <span>
                    {{ workspace.maxTokens }}
                  </span>
                </div>
              </div>
            </div>
            <div class="section">
              <div class="title">
                Number of latest included messages
              </div>
              <div class="content">
                <div class="maxTokens">
                  <v-slider
                      v-model="historyLimit"
                      :min="0"
                      :max="50"
                      :disabled="includeAllHistory || !canEdit()"
                  ></v-slider>
                  <span>
                      {{ historyLimit }}
                  </span>
                </div>
                <v-checkbox v-if="isShAdmin() && canEdit()" label="Include All" v-model="includeAllHistory">
                </v-checkbox>
              </div>
            </div>
            <div class="section" v-if="canEdit()">
              <div class="title">
                Privacy
              </div>
              <div class="content">
                <div class="inline">
                  <div class="inlineItem">
                    <v-btn style="" icon @click.stop="workspace.private = !workspace.private">
                      <v-icon big> {{ workspace.private ? 'mdi-lock' : 'mdi-lock-open-variant-outline' }} </v-icon>
                    </v-btn>
                    <span>{{ workspace.private ? 'Private' : 'Public' }}</span>
                  </div>
                  <div class="inlineItem">
                    <v-btn style="" icon @click.stop="isParticipantDialogOpen = true">
                      <v-icon big> mdi-account-supervisor-circle </v-icon>
                    </v-btn>
                    <span>
                    Participants
                  </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-row class="Workspace__sessions">
        <div class="titleWrapper">
          <span class="title">
            Your sessions
          </span>
        </div>
        <div class="list">
          <div class="item" v-for="session in sessions" :key="session.id" @click="goToSession(session.id)">
            <span>
              {{ session.name }}
            </span>
            <span>
              {{ getTime(session.createdAt) }}
            </span>
          </div>
        </div>
      </v-row>
    </v-col>
    <v-col v-if="workspaceLoading">
      <Loader></Loader>
    </v-col>
    <tools-select :dialog="isToolSelectDialog" :toolSets="workspace.tools" @close="isToolSelectDialog = false" @toolsChanged="(data) => { workspace.tools = data}"/>
    <workspace-participant :dialog="isParticipantDialogOpen" @close="isParticipantDialogOpen = false"/>
    <workspace-knowledge :dialog="isWorkspaceKnowledgeOpen" @close="isWorkspaceKnowledgeOpen = false" :json-knowledge="workspace.jsonKnowledge" @jsonKnowledgeChanged="(data) => {workspace.jsonKnowledge = data; this.updateWorkspaceInfo()}"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: customerGetters } = createNamespacedHelpers('customer');
const {
  mapGetters: AIChatGetters,
  mapActions: AIChatActions,
  mapMutations: AIChatMutations
} = createNamespacedHelpers('AIChat');
const { mapGetters: workspacesGetters, mapActions: workspaceActions, mapMutations: workspacesMutations } = createNamespacedHelpers('workspace');

import debounce from "lodash/debounce";

import * as moment from 'moment';
import ToolsSelect from "@/views/ai-workspaces/create-workspace/tools-select/tools-select.vue";
import Loader from "@/components/loader/loader.vue";
import lodashGet from "lodash/get";
import constants from "@/util/constants";
import WorkspaceParticipant from "@/views/ai-workspaces/workspace/participants/participants.vue";
import WorkspaceKnowledge from "@/views/ai-workspaces/workspace/knowledge/workspace-knowledge.vue";
import WorkspacePrompt from "@/views/ai-workspaces/workspace/workspace-prompts/workspace-prompts.vue";

export default {
  name: 'WorkspaceDetails',
  components: {WorkspacePrompt, WorkspaceKnowledge, WorkspaceParticipant, ToolsSelect, Loader},
  data() {
    return {
      isWorkspaceKnowledgeOpen: false,
      memory: {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      message: '',
      publicPath: constants.isDevelopment ? "" : "",
      includeAllHistory: false,
      isToolSelectDialog: false,
      isParticipantDialogOpen: false,
      historyLimit: 15,
      models: [
        {
          label: 'Claude V3 Sonnet',
          value: 'anthropic.claude-3-sonnet-20240229-v1:0'
        },
        {
          label: 'Claude V3.5 Sonnet',
          value: 'anthropic.claude-3-5-sonnet-20240620-v1:0'
        },
        {
          label: 'Claude V3 Haiku',
          value: 'anthropic.claude-3-haiku-20240307-v1:0'
        },
        {
          label: 'Claude V3.5 Haiku',
          value: 'anthropic.claude-3-5-haiku-20241022-v1:0'
        },
        {
          label: 'Claude V2',
          value: 'anthropic.claude-v2:1'
        }
      ],
      selectedModel: 'anthropic.claude-3-haiku-20240307-v1:0',
      files: [],
      filesAccumulated: [],
      selectedSystemPrompt: null,
      systemPromptText: '',
    };
  },
  computed: {
    ...customerGetters({
      customer: 'CUSTOMER',
      selectedCustomer: 'SELECTED_CUSTOMER',
      user: 'USER'
    }),
      ...AIChatGetters({
        systemPrompts: 'SYSTEM_PROMPTS',
        systemPromptLoading: 'SYSTEM_PROMPT_LOADING',
        updateSystemPromptLoading: 'UPDATE_SYSTEM_PROMPT_LOADING',
        sendMessageLoading: 'SEND_MESSAGE_LOADING',
      }),
    ...workspacesGetters({
      workspace: 'WORKSPACE',
      sessions: 'WORKSPACE_SESSIONS',
      updateWorkspaceLoading: 'WORKSPACE_UPDATE_LOADING',
      workspaceLoading: 'WORKSPACE_LOADING',
      sessionsLoading: 'WORKSPACE_SESSIONS_LOADING',
      workspaceActionLoading: 'IS_WORKSPACE_PROMPTS_ACTION_LOADING',
      selectedPrompt: 'SELECTED_WORKSPACE_PROMPT',
      workspacePrompts: 'WORKSPACE_PROMPTS',
    })
  },
  watch: {
    'selectedCustomer.customer_id'() {
      //this.getInfo();
    },
  },
  methods: {
    ...AIChatActions(['sendMessage', 'getToolSets', 'getSystemPrompts', 'updateSystemPrompt', 'createSystemPrompt']),
    ...AIChatMutations({
      setSendMessageLoading: 'SET_SEND_MESSAGE_LOADING',
      setSendMessage: 'SET_SEND_MESSAGE',
      setChatWorkspace: 'SET_CHAT_WORKSPACE',
    }),
    ...workspacesMutations({
      pushToWorkspacePrompt: 'PUSH_TO_WORKSPACE_PROMPTS',
      setWorkspacePrompts: 'SET_WORKSPACE_PROMPTS',
    }),
    ...workspaceActions(['getWorkspace', 'getWorkspaceSessions', 'updateWorkspace', 'createWorkspacePrompt', 'updateWorkspacePrompt']),
    async saveWorkspacePrompt() {
      if(this.selectedPrompt?.id) {
        const prompt = await this.updateWorkspacePrompt({
          prompt: this.message,
          workspaceId: this.workspace.id,
          workspacePromptId: this.selectedPrompt?.id,
        });
        const prompts = [...this.workspacePrompts];
        const index = prompts.findIndex((item) => item?.id === prompt?.id);
        prompts[index] = prompt;
        this.setWorkspacePrompts([...prompts]);
        return;
      }
      const prompt = await this.createWorkspacePrompt({
        prompt: this.message,
        workspaceId: this.workspace.id,
      });
      this.pushToWorkspacePrompt(prompt);
    },
    createObjectURL(file) {
      return URL.createObjectURL(file)
    },
    isShAdmin() {
      return lodashGet(this.customer, 'customer_id') === 0;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async saveSystemPrompt() {
      if(this.selectedSystemPrompt?.id) {
        await this.updateSystemPrompt({
          systemPromptId: this.selectedSystemPrompt.id,
          prompt: this.systemPromptText,
        });
        return;
      }
      const prompt = await this.createSystemPrompt({
        prompt: this.systemPromptText,
        name: this.systemPromptText,
      })
      this.systemPrompts.push(prompt);
      this.selectedSystemPrompt = prompt;
      this.systemPromptText = prompt.prompt;
    },
    async updateSelectedSystemPrompt(value) {
      const systemPrompt = this.systemPrompts.find((prompt) => prompt.id === value);
      this.systemPromptText = systemPrompt.prompt;
      this.selectedSystemPrompt = systemPrompt;
    },
    getTime(date) {
      return moment(date).fromNow()
    },
    onFileDelete(index) {
      this.filesAccumulated.splice(index, 1);
      this.files = [];
    },
    onFileChange(event) {
      event.forEach(thisFile => {
        if(this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError',`${thisFile.name} too big`)
          return;
        }
        this.filesAccumulated.push(thisFile)
      })
      this.files = [];
    },
    async prepareFiles() {
      const prepared = await Promise.all(this.filesAccumulated.map(async (file) => {
        const base64 =  await this.toBase64(file)
        return {
          base64: base64,
          name: file.name,
          type: file.type
        }
      }));
      return prepared;
    },
    validateSize(input) {
      const fileSize = input.size / 1024 / 1024; // in MiB
      return fileSize > 1;
    },
    debounceInput: debounce(function (e) {
      this.search = e;
    }, 1000),
    async getInfo(workspaceId) {
      this.setSendMessageLoading(false);
      await this.getWorkspace({ workspaceId });
      await this.getWorkspaceSessions({ workspaceId })
      this.selectedSystemPrompt = this.workspace?.SystemPrompt;
      this.systemPromptText = this.selectedSystemPrompt?.prompt
      this.selectedModel = this.models.find((model) => model.value === this.workspace.model).value;
      this.historyLimit = this.workspace.historyLimit
      this.includeAllHistory = this.workspace.historyLimit === null || this.workspace.historyLimit === undefined;
      this.setChatWorkspace(this.workspace);
      await this.getSystemPrompts();
    },
    async updateWorkspaceInfo() {
      await this.updateWorkspace({
        workspaceId: this.workspace.id,
        toolSetIds: this.workspace.tools.map(tool => tool.id),
        systemPromptId: this.selectedSystemPrompt?.id,
        model: this.selectedModel,
        name: this.workspace.name,
        maxTokens: this.workspace.maxTokens,
        historyLimit: this.includeAllHistory ? null : this.historyLimit,
        private: this.workspace.private,
        jsonKnowledge: this.workspace.jsonKnowledge,
      });
    },

    async send() {
      if(this.message === '') {
        return;
      }
      const messageValue = this.message.valueOf();
      this.message = '';
      this.setSendMessageLoading(true);

      this.setChatWorkspace(this.workspace)
      this.setSendMessage({
        workspaceId: this.workspace.id,
        workspace: this.workspace,
        files: this.filesAccumulated,
        messageValue,
        memory: this.memory,
      })

      this.$router.push({ path: `/ai-chat` });
    },

    goToSession(id) {
      this.$router.push({ path: `/ai-chat`, query: { session: id } });
    },

    canEdit() {
      return this?.user?.is_privileged
    }
  },
  async mounted() {
    const { workspaceId } = this.$route.params;
    await this.getInfo(workspaceId);
    document.getElementById('message_input2').addEventListener("keydown", async (e) => {
      const getCaret = (el) => {
        if (el.selectionStart) {
          return el.selectionStart;
        } else if (document.selection) {
          el.focus();
          const r = document.selection.createRange();
          if (r == null) {
            return 0;
          }
          const re = el.createTextRange(), rc = re.duplicate();
          re.moveToBookmark(r.getBookmark());
          rc.setEndPoint('EndToStart', re);
          return rc.text.length;
        }
        return 0;
      }

      const start = e.target.selectionStart
      const end = e.target.selectionEnd
      const caret = getCaret(e.target);
      if (e.key == "Enter" && e.ctrlKey == false && e.shiftKey == false && this.message.replaceAll('\n', '') !== '') {
        await this.send()
      }
      if (e.key == "Enter" && e.ctrlKey == true) {
        this.message = this.message.substring(0, caret) + "\n" + this.message.substring(end);
        e.stopPropagation();
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1;
        },1)
      }
      if (e.key == "Enter" && e.shiftKey == true) {
        this.message = this.message.substring(0, caret) + "\n" + this.message.substring(end);
        e.stopPropagation();
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = start + 1;
        },1)
      }
    })
    document.getElementById('message_input2').onpaste = (event) => {
      const items = (event?.clipboardData || event?.originalEvent?.clipboardData).items;
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          const blob = item.getAsFile();
          this.filesAccumulated ??= [];
          this.filesAccumulated.push(blob)
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import './workspace';
</style>
