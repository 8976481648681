<template>
  <v-dialog :value="dialog" persistent max-width="60%" max-height="60%" @click:outside="$emit('close')">
    <v-card>
      <v-card-title class="text-h5"> Workspace Knowledge Base </v-card-title>

      <span style="margin-left: 25px">Format: { "data": value, "data2": value }</span>

      <v-checkbox style="margin-left: 25px" v-model="isNowSet" label="Include current date and time" @change="includeNow"></v-checkbox>
      <div class="px-5 my-5 d-flex">
        <JsonEditorVue style="width: 98%" :mode="'text'" v-model="jsonKnowledge"/>
        <v-row style="margin-top: 12px; margin-left: 15px">
          <v-file-input
              label="File input"
              hide-input
              v-model="files"
              class="WorkspaceKnowledge__attach_button"
              multiple
              accept=".doc,.docx,.pdf,.csv,.txt,.html,.odt,.rtf,.epub,.jpeg,.png,.jpg,image/*"
              @change="onFileChange"
          ></v-file-input>
        </v-row>
      </div>

      <v-row class="WorkspaceKnowledge__files" v-if="files.length !== 0">
        <div class="WorkspaceKnowledge__filesContainer" v-if="!isFilesLoading">
          <div v-for="(file, index) in files" :key="index" class="fileItem">
            <div style="position:relative; display: flex; justify-content: start">
              <button @click.stop="onFileDelete(file.id)" class="closeButton">
                <span>&times;</span>
              </button>
              <img v-if="file.type.includes('image')" :src="file.base64" class="image"/>
              <div style="height: 80px">
                <img v-if="!file.type.includes('image')" class="document" :src="`${publicPath}/icons/file-icon.svg`"/>
              </div>
              <span style="top: 0px">
                   {{ file.name }}
                 </span>
            </div>
          </div>
        </div>
        <v-progress-circular
            :size="25"
            :width="2"
            v-if="isFilesLoading"
            color="primary"
            indeterminate
            style="margin-left: 49%; margin-right: 49%"
        />

        <v-progress-linear striped :value="getFilesSize()" :height="10">
          <template v-slot:default="{ value }">
            <span style="font-size: 10pt">{{ Math.ceil(value) }}%</span>
          </template>
        </v-progress-linear>
      </v-row>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')"> Cancel </v-btn>
        <v-btn color="blue darken-1" text @click="updateMemory"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import JsonEditorVue from "json-editor-vue";
import cloneDeep from "lodash/cloneDeep";
import constants from "@/util/constants";

const { mapGetters: workspacesGetters, mapActions: workspaceActions } = createNamespacedHelpers('workspace');

export default {
  name: 'WorkspaceKnowledgeKnowledge',
  components: {JsonEditorVue},
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    jsonKnowledge: {
      type: Object,
    }
  },
  data() {
    return {
      isNowSet: true,
      publicPath: constants.isDevelopment ? "" : "",
      filesSize: 0,
    }
  },
  watch: {
    dialog() {
      if(!this.jsonKnowledge) {
        this.jsonKnowledge = {
          now: true,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }
      if(!this.jsonKnowledge.now) {
        this.isNowSet = false;
      } else {
        this.isNowSet = true;
      }
      this.getWorkspaceKnowledge({ workspaceId: this.workspace.id });
    }
  },
  computed: {
    ...workspacesGetters({
      files: 'WORKSPACE_KNOWLEDGE_FILES',
      workspace: 'WORKSPACE',
      isFilesLoading: 'WORKSPACE_KNOWLEDGE_FILES_LOADING',
    })
  },
  methods: {
    ...workspaceActions(['getWorkspaceKnowledge','addWorkspaceKnowledge','deleteWorkspaceKnowledge']),
    getFilesSize() {
      let size = 0;
      this.files.map((file) => {
        size += Number(file.size);
      })
      this.filesSize = size / 1024 / 1024;
      return size / 1024 / 10.24;
    },
    validateSize(input) {
      return this.filesSize + (input.size / 1024 / 1024) > 1;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
      })
    },
    async onFileDelete(id) {
      await this.deleteWorkspaceKnowledge({ workspaceId: this.workspace.id, knowledgeId: id })
    },
    async onFileChange(events) {
      for(let thisFile of events) {
        if(this.validateSize(thisFile)) {
          this.$store.dispatch('notifications/setError',`Knowledge size limit exceeded`)
          return;
        }
        const base64 =  await this.toBase64(thisFile)
        await this.addWorkspaceKnowledge(
            {
              files: [{
                workspaceId: this.workspace.id,
                base64: base64,
                type: thisFile.type,
                name: thisFile.name,
                size: thisFile.size,
              }],
              workspaceId: this.workspace.id,
            }
        )
      }
    },
    includeNow(value) {
      let jsonKnowledgeData = this.jsonKnowledge;
      jsonKnowledgeData ??= {};
      if(typeof jsonKnowledgeData === 'string') {
        jsonKnowledgeData = JSON.parse(jsonKnowledgeData);
      }
      jsonKnowledgeData['now'] = value;
      if(value) {
        jsonKnowledgeData['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
      } else {
        delete jsonKnowledgeData['timezone'];
      }
      this.jsonKnowledge = cloneDeep(jsonKnowledgeData);
    },
    async updateMemory() {
      this.$emit('jsonKnowledgeChanged', this.jsonKnowledge)
      this.$emit('close');
    },
  },
  mounted() {
    if(!this.jsonKnowledge) {
      this.jsonKnowledge = {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
    if(!this.jsonKnowledge.now) {
      this.isNowSet = false;
    }
  },
  created() {
    if(!this.jsonKnowledge) {
      this.jsonKnowledge = {
        now: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }
    }
    if(!this.jsonKnowledge.now) {
      this.isNowSet = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import 'workspace-knowledge';
</style>
